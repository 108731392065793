<template>

    <div class="container">
      <div class="row justify-content-md-center mt-4 mb-2 pb-row">
        <div class="col-12 col-lg-8 text-center login-signup">
          <h4>Sign Up</h4>
        </div>
      </div>

      <div class="row justify-content-md-center mt-4 mb-2 pb-row">
        <div class="col-12 col-lg-8 text-center pb-5 login-signup-form">
          <div v-if="!isShowingLoginDetails" class="row justify-content-center">
            <div class="preLoginButton imgBtnContainer" @click="isShowingLoginDetails = true;">
               <img src="@/assets/img/email.svg" />
               <span>Signup with Email</span>
            </div>         
          </div>
          <div v-if="isShowingLoginDetails" class="row">
            <div class="col-12 col-lg-8 text-left pb-4 mg-auto">
              <form>
                <div class="form-group">
                  <label for="inputEmail1">Email</label>
                  <input type="email" v-model="username" class="form-control" id="inputEmail1" aria-describedby="emailHelp" placeholder="Enter email" autofocus>
                  <!--<small id="emailHelp" class="form-text text-muted">We'll never share your email with anyone else.</small>-->
                </div>

                <div class="form-group">
                  <label for="inputPassword1">Password</label>
                  <input type="password" v-model="password1" class="form-control" id="inputPassword1" placeholder="password">
                </div>

                <div class="form-group">
                  <label for="inputPassword1">Repeat Password</label>
                  <input type="password" v-model="password2" class="form-control" id="inputPassword2" placeholder="repeat password">
                </div>

               <b-list-group v-show="errors.length > 0">
                  <b-list-group-item
                  v-for="error in errors"
                  :key="error"
                  variant="danger"
                  >{{ error }}</b-list-group-item
                  >
               </b-list-group>

                <a href="login" @click="$event.preventDefault(); submitForm();" class="btn btn-orange btn-lg btn-block login-btn mt-5">Signup With Email</a>
              </form>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-lg-8 text-center mg-auto">
            <hr>
          </div>
          <div class="col-12 col-lg-8 text-center wallet-connect-btn mg-auto">
             <div class="row justify-content-center">
               <div class="preLoginButton imgBtnContainer" @click="$event.preventDefault(); submitCryptoForm();">
                  <img src="@/assets/img/money-bag.svg" />
                  <span href="login">Signup With A Wallet</span>
               </div>            
             </div>

          </div>
        </div>

         <div class="row">
            <div class="col-12 col-lg-8 text-center mg-auto">
               <div class="ssoButton imgBtnContainer" onclick="document.location.assign('/accounts/google/login/')">
                  <img src="@/assets/img/logo_googleg_48dp.svg" />
                  <span>Google</span>
               </div>         
               <div class="ssoButton imgBtnContainer" onclick="document.location.assign('/accounts/twitter/login/')">
                  <img src="@/assets/img/twitter-logo.svg" />
                  <span>Twitter</span>
               </div>         
               <div class="ssoButton imgBtnContainer" onclick="document.location.assign('/accounts/apple/login/')">
                  <img src="@/assets/img/Apple_logo_black.svg" />
                  <span>Apple</span>
               </div>         
            </div>
         </div>


      </div>
    </div>

    </div><!--END CONTAINER-->

</template>

<script>
import { convertUtf8ToHex } from "@walletconnect/utils";
import  { hashMessage, getDateAndRandomValueString } from "@/utils/wallet"

import Web3Modal from 'web3modal';
import WalletConnectProvider from "@walletconnect/web3-provider";
import { ethers } from "ethers";

export default {
   name: "SignUp",
   data() {
      return {
         username: "",
         password1: "",
         password2: "",
         errors: [],
         connector: null,
         isShowingLoginDetails: false,
      };
   },
   mounted() {
      window.gtag("event", "SignUpPage");
   },
   methods: {
      submitForm() {
         this.errors = [];
         if (this.password1 !== this.password2 && this.password1 !== "") {
            this.errors.push("The entered passwords must match.");
         }

         const to_send = {
            username: this.username,
            password: this.password1,
         };

         if (this.errors.length === 0) {
            this.axios
               .post("/api/signup", to_send, { withCredentials: true })
               .then((response) => {
                  this.$store.commit("setIsWallet", false);
                  this.$store.commit("setNeedsEmailConfirm", true);

                  this.$router.push("/confirm");
                  console.log(response);
               })
               .catch((error) => {
                  if (error.response) {
                     let message = "Unable to sign up with these credentials. Please check your entry and try again.";
                     if (error.response.data.length < 100) {
                        message += " (" + error.response.data + ")";
                     }
                     this.errors.push(message);
                     console.log(this.errors);
                  } else if (error.message) {
                     this.errors.push("Something went wrong, please try again.");
                  }
               });
         }
      },
      showMsgBox(title, message) {
         this.$bvModal.msgBoxOk(message, {
            id: "info-modal",
            title: title,
            size: 'sm',
            buttonSize: 'sm',
            headerClass: 'p-2 border-bottom-0',
            footerClass: 'p-2 border-top-0',
            centered: true
         });
      },
      async submitCryptoForm() {
       const infuraID = "b125812f07ef44e0b8deb5c13b7ab5f0";

         const providerOptions = {
            walletconnect: {
               package: WalletConnectProvider,
               options: {
                  infuraId: infuraID
               }
            },
         };

         const web3Modal = new Web3Modal({
            cacheProvider: false,
            providerOptions
         });

         this.web3Modal = web3Modal;
         await this.web3Modal.clearCachedProvider();

         try {
            let provider = await web3Modal.connect();
            console.log(provider);

            const library = new ethers.providers.Web3Provider(provider);
            this.library = library;
            console.log(library);

            const accounts = await library.listAccounts();
            console.log(accounts);

            // Add a random number and datestamp to the message
            const msg = `Login to your Xecret.io account. ${getDateAndRandomValueString()}`;
            this.showMsgBox('Pending Request', 'Approve or reject using your wallet...');

            const msgParams = [
               convertUtf8ToHex(msg),
               accounts[0]
            ];
            console.log(msgParams)

            library.provider.request({
               method: "personal_sign",
               params: msgParams
            }).then((result) => {
               console.log(result);

               // get nonce from server
               this.axios
                  .get("/api/nonce", { withCredentials: true })
                  .then((response) => {
                     console.log(response.data.nonce);


                     const to_send = {
                        nonce: response.data.nonce,
                        address: accounts[0],
                        signature: result,
                        token: hashMessage(msg),
                     };
                     console.log(to_send)

                     this.axios
                        .post("/api/wallet-signup", to_send, { withCredentials: true })
                        .then((response) => {
                           console.log(response);
                           // auto login

                           this.$store.commit("login");
                           this.$store.commit("setAccountData", response.data.account_data);
                           this.$store.commit("setIsWallet", true);

                           this.$router.push("/payment");
                        })
                        .catch((error) => {
                           this.$bvModal.hide("info-modal");
                           this.showMsgBox('Pending Request', 'Request canceled.');

                           if (error.response) {
                              this.errors.push("Invalid entry; please check your inputs and try again.");
                           } else if (error.message) {
                              this.errors.push("Something went wrong, please try again.");
                           }
                        });
                  })
                  .catch((error) => {
                     if (error.response) {
                        this.errors.push("Invalid entry; please check your inputs and try again.");
                     } else if (error.message) {
                        this.errors.push("Something went wrong, please try again.");
                     }
                  });
            })
            .catch((error) => {
               console.log("this error happened");
               console.error(error);

               this.$bvModal.hide("info-modal");
               this.showMsgBox('Pending Request', 'Request canceled.');
            });

            provider.on("connect", (chainId) => {
               console.log("connect");
               console.log(chainId);
            });


            // Subscribe to accounts change
            provider.on("accountsChanged", (accounts) => {
               console.log("accounts changed");

               // Get updated accounts and chainId
               if (this.$store.state.loggedIn) {
                  console.warn("changed wallet address");
                  if (accounts[0] !== this.$store.username) {
                     this.axios
                        .post("/api/logout", {}, { withCredentials: true })
                        .then((response) => {
                           console.log(response);
                           this.$store.commit('logout');
                        })
                        .catch((error) => {
                           console.log(error);
                        });

                     this.$router.push({name: "Login"});
                  }
               }
            });

            // Subscribe to chainId change
            provider.on("chainChanged", (chainId) => {
               console.log("chain changed");
               console.log(chainId);
               // ignore for now
            });


            // Subscribe to session disconnection
            provider.on("disconnect", (code, reason) => {
               console.log("disconnect");
               console.log(code, reason);
            });

            this.provider = provider;
         }
         catch(e) {
            console.error(e);
         }
      }
   },
};
</script>
